import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Tab, Tabs, css, styled } from '@mui/material';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function App() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <AppBar position="static" style={{height:'80px'}}>
      <Container  style={{width:'100%',maxWidth:'100%',background:'white',height:'100%',paddingLeft:'0px'}}>
        <Toolbar disableGutters style={{height:'100%'}}>
          <img src='/images/logo.png' style={{height:'90%'}}/>

          <Box sx={{ borderBottom: 1, borderColor: 'divider',marginTop:'21px',display:'flex',height:'59px' ,paddingLeft:'40px'}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="首页" {...a11yProps(0)} style={{fontSize:'18px',fontWeight:'bolder'}}/>
              <Tab label="解决方案" {...a11yProps(1)} style={{fontSize:'18px',fontWeight:'bolder'}}/>
              <Tab label="成功案例" {...a11yProps(2)} style={{fontSize:'18px',fontWeight:'bolder'}}/>
              <Tab label="公司介绍" {...a11yProps(2)} style={{fontSize:'18px',fontWeight:'bolder'}}/>
            </Tabs>
          </Box>
          <Box style={{flex:1,color:'red',height:'59px',display:'flex',justifyContent:'end',alignItems:'center',paddingRight:'60px'}}>
            <img src='/images/phone.png' style={{height:'80%'}}/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
      <Box>
        <CustomTabPanel value={value} index={0}>
            <img src='/images/tab1.png' style={{width:'100%'}}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <img src='/images/tab2_top.png' style={{width:'100%'}}/>
          <ClickView></ClickView>
          <img src='/images/tab2_bottom.png' style={{width:'100%'}}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <img src='/images/tab3.png' style={{width:'100%'}}/>
          <CaseView></CaseView>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <img src='/images/tab4.png' style={{width:'100%'}}/>
        </CustomTabPanel>
      </Box>
    </Box>
    
  );
}
export default App;

const ClickView = ()=>{
  const [select ,setSelect] = React.useState(0);


  return <Box style={{display:'flex',height:'260px',padding:'0% 15%'}}>
    <ClickItem select={select} nindex={0} setSelect={setSelect} title={'项目初期评估'} content={'准备详细问卷，在接触客户之前让客户填写问卷初期评价基于问卷进行远程沟通，或者上门拜访，了解详细需求'}></ClickItem>
    <ClickItem select={select} nindex={1} setSelect={setSelect} title={'确定SOW\n项目报价'} content={'确定SOW基于初步沟通做数据资产入表项目SOW规划，例如是否做数据治理、是否做项目报价确权登记，是否协助做银行贷款等？不要将最终融资目标作为项目的付款条件；'}></ClickItem>
    <ClickItem select={select} nindex={2} setSelect={setSelect} title={'项目启动'} content={'1、专业项目整体规划文档，四院可以协助做，分出那些是可资产化数据；项目启动2、数据评价（样本通）；3、数据资产评估、入表'}></ClickItem>
    <ClickItem select={select} nindex={3} setSelect={setSelect} title={'项目交付'} content={'基于标准合同流程，数据资产项目完成“入表”即视为标准流程走完；项目交付要非常谨慎承诺项目与审计公司结果对赌。'}></ClickItem>
    <ClickItem select={select} nindex={4} setSelect={setSelect} title={'后期融资'} content={'可以考虑介绍熟悉的银行，给入表企业做贷款咨询，这块业务企业非常欢迎。'}></ClickItem>
  </Box>
}

const ClickItem = (_props: any)=>{
  const {select,nindex,setSelect,title,content} = _props
  const Container = styled(Box)(({ theme }) => ({

    '--jiao':select == nindex? "#276b8f":'#f1f8ff',
  }));
  return <Container onMouseEnter={()=>{
    setSelect(nindex)
  }} className='demo' style={{width: '100%',flex:select == nindex?'1.6':'1',margin:'15px',backgroundImage:select == nindex?'linear-gradient(179deg, #3585af, #154863)':'linear-gradient(179deg, white, #e4f1ff)',border:'1px solid gray',borderRadius:'10px',  content: ' '
}}>
    <Box style={{fontWeight:'bolder',fontSize:'70px',lineHeight:'0.9',height:(70*0.9/2+'px'),color:select == nindex?'#d2e7ff':'#d2e7ff',overflow:'hidden'}}>{'0'+(nindex+1)}</Box>
    {select == nindex&&<Box style={{color:select == nindex?'white':'unset',position:'absolute',top:'0px',right:'0px',height:'32px',display:'flex',alignItems:'center',paddingRight:'15px',maxWidth:'55%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{title}</Box>}
    <Box style={{marginTop:'0px',color:select == nindex?'white':'unset',zIndex:12,position:'relative',height:'calc(100% - 31px)',borderRadius:'0px 0px 8px 8px',fontSize:select == nindex?'15px':'19px',display:'flex',alignItems:'center',justifyContent:'center',padding:'0px 15px'}}>{select == nindex?content:title}</Box>
  </Container>    
}


const CaseView = ()=>{
  const [select ,setSelect] = React.useState(-1);

  return <Box>
  <Box style={{display:'flex',padding:'0% 15%',flexWrap:'wrap',marginBottom:'50px'}}>
        <CaseItem  titleType='image' select={select} nindex={0} setSelect={setSelect} title={'中国光大银行'} content={'助力银行实现全域、多元数据资产统一管理，提供高质量、智能化的全领域数据资产'} 
        detail ={'2021年1月22日，中国光大银行召开“点数成金，值得你来”发布会，正式发布《商业银行数据资产估值白皮书》, 根据此方法，《白皮书》以光大银行为研究对象，针对17个估值对象确定了17个数学计算公式，结合111个计算参数，明确出198个计算指标及口径。最终采集了198个指标数据，计算出光大银行目前的数据资产价值超过千亿元。'}
        ></CaseItem>
        <CaseItem  titleType='image' select={select} nindex={1} setSelect={setSelect} title={'恒丰银行'} content={'帮助企业对数据标准进行系统化管理，解决监管平台数据质量低等问题'}
        detail={'2023年12月，恒丰银行牵头，中债登、中国银行、蚂蚁科技等10余家单位参与编写了《金融数据资产估值与交易研究》，围绕金融数据资产估值与交易主线，从数据资产确权、估值、入表和交易方面展开系统研究。恒丰银行后发布了《商业银行数据资产估值研究与入表探索》白皮书，为加速数据要素定价统一、激发数据价值内生动力提供重要的参考借鉴。基于构建的估值体系，恒丰银行进行了数据资产的估值实践。经测算，该行数据资产总价值221亿元，数据资产的投入价值52亿元，业务价值169亿元。'}
        ></CaseItem>
        <CaseItem  titleType='image' select={select} nindex={2} setSelect={setSelect} title={'上海银行'} content={'帮助企业对数据标准进行系统化管理，解决监管平台数据质量低等问题'}
        detail={'2023年11月26日，上海银行联合德勤、上海数据交易所，发布了《商业银行数据资产体系白皮书》。上海银行已积累数据资产14类5万余项，包括3500多个核心经营指标，6300多个标签，2700多个基础数据模型，该行也是全国首家通过“数据管理能力成熟度评估模型（DCMM）”量化管理级（4级）的城市商业银行。'}
        ></CaseItem>
        <CaseItem  titleType='text' select={select} nindex={3} setSelect={setSelect} title={'广东广州'} content={'助力银行实现全域、多元数据资产统一管理，提供高质量、智能化的全领域数据资产'}
        detail={'随着数字经济的迅猛发展，数据作为新型资产形态的价值日益凸显。广东联数据交易中心等单位组成联盟为本次数据资源入表和数据产品定价提供服务。合电子服务股份有限公司作为行业首家、全国首批数据资产入表的企业之一，目前，浙江五疆科技发展有限公司已完成数据资源入表准备，并正式启动入于2024年1月1日正式将数据资产计入财务报表，在推动数据资产化上迈出表工作。了突破性一步，具有重大战略意义'}
        ></CaseItem>
        <CaseItem titleType='text' select={select} nindex={4} setSelect={setSelect} title={'四川成都'} content={'帮助企业对数据标准进行系统化管理，解决监管平台数据质量低等问题'}
        detail={'按照财政部《企业数据资源相关会计处理暂行规定》（以下简称：《暂行规时间：2024.01.12定》），从2024年1月1日起，企业可以把数据资产在资产负债表相关科目2024年1月11日，在河南数据集团揭牌成立一周年之际，数据集团‘企业对数据资源进行列报和披露，这意味着我国数据资产化的第一步已然迈出。土地使用权’数据在郑州数据交易中心挂牌上市，获颁“数据产权登记证集团下属成都数据集团作为成都市数据要素市场一级开发主体和数字经济生书”。金融机构根据会计师事务所、资产评估事务所、律师事务所有关意见，态营造者，率先完成数据资产入表0到1的关键一步，成为西南地区首批数向河南数据集团批准授信额度800万元，完成了河南省首笔数据资产无抵押据资产入表企业。'}
        ></CaseItem>
        {/* <CaseItem titleType='text' select={select} nindex={5} setSelect={setSelect} title={'天津'} content={'帮助企业对数据标准进行系统化管理，解决监管平台数据质量低等问题'}
        detail={'2024年1月1日，天津市首单数据资产入表登记评估工作顺利完成，河北区1月19日，泉州数据要素生态大会暨泉州市大数据产业协会会员大会成功举供热公司获得《数据资产登记证书》，并成为天津市首个具备数据资产入办。会上，发布了首单成功入表的数据资产——泉数工采通数据集，权属表条件的国有企业。'}
        ></CaseItem> */}
        <CaseItem titleType='text' select={select} nindex={5} setSelect={setSelect} title={'河南郑州'} content={'帮助企业对数据标准进行系统化管理，解决监管平台数据质量低等问题'}
        detail={'2024年1月11日，在河南数据集团揭牌成立一周年之际，数据集团‘企业对数据资源进行列报和披露，这意味着我国数据资产化的第一步已然迈出。土地使用权’数据在郑州数据交易中心挂牌上市，获颁“数据产权登记证集团下属成都数据集团作为成都市数据要素市场一级开发主体和数字经济生书”。金融机构根据会计师事务所、资产评估事务所、律师事务所有关意见，态营造者，率先完成数据资产入表0到1的关键一步，成为西南地区首批数向河南数据集团批准授信额度800万元，完成了河南省首笔数据资产无抵押据资产入表企业。融资案例。'}
        ></CaseItem>

 </Box>
 <img src='/images/bottom.png' style={{width:"100%"}}/>
 </Box>
}

const CaseItem = (_props: any)=>{
  const {select,nindex,setSelect,title,content,detail,titleType} = _props
  const Container = styled(Box)(({ theme }) => ({

    '--jiao':select == nindex? "#276b8f":'#f1f8ff',
  }));
  return <Container onMouseEnter={()=>{
    setSelect(nindex)
  }} className='container' style={{paddingBottom: 'calc(6.25%)',width: '30%',margin:'15px',backgroundImage:select == nindex?'linear-gradient(179deg, #3585af, #154863)':'white',border:'1px solid gray',borderRadius:'10px',  content: ' '
}}>
    <Box style={{flexDirection:'column',fontWeight:'bolder',fontSize:'70px',lineHeight:'0.9',color:select == nindex?'#d2e7ff':'#d2e7ff',overflow:'hidden',display:"flex",alignItems:'center',justifyContent:"center",paddingTop:'8px'}}>
      {titleType==='image'&&<img style={{height:'60px'}} src={`/images/case_${nindex}.png`}></img>}
      {titleType==='text'&&<Box style={{lineHeight:'1.6em',fontSize:'30px',color:'black'}} >
        {title}</Box>}
      <img style={{width:"90%",paddingTop:'10px'}} src={`/images/case_image_${nindex}.png` }></img>
      <Box style={{fontSize:'16px',color:'black',padding:'30px 10px',lineHeight:'1.4em',fontWeight:'lighter'}}>
        {content}
      </Box>
    </Box>
    {select == nindex&&<Box style={{display:'flex',flexDirection:'column',color:select == nindex?'white':'unset',backgroundImage:'linear-gradient(179deg, #3585af, #154863)',position:'absolute',top:'0px',right:'0px',left:'0px',bottom:'0px',alignItems:'center',padding:'15px',overflow:'auto',borderRadius:'8px'}}>
        <Box style={{fontSize:'18px',fontWeight:'bolder',paddingBottom:'10px'}}>
          {title}
        </Box>
        <Box style={{fontSize:'15px'}}>
          {detail}
        </Box>
      </Box>}
  </Container>    
}